var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "default-header expandHeader" },
    [
      _c(
        "v-container",
        { staticClass: "pl-4 pb-7 pr-4 d-flex align-center pt-8" },
        [
          _c(
            "v-row",
            { attrs: { align: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "d-md-none", attrs: { cols: "2", md: "4" } },
                [_c("the-default-mobile-navigation", { staticClass: "ml-n2" })],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center",
                  attrs: { cols: "8", md: "8" },
                },
                [
                  _c("v-img", {
                    staticClass: "cursor--pointer mr-md-14 xdc-header-image",
                    class: _vm.$vuetify.breakpoint.smAndDown ? "mx-auto" : "",
                    attrs: {
                      src: require("@/assets/images/icons/logo-mew.svg"),
                      "max-height": "36",
                      "max-width": "130",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          name: _vm.ROUTES_HOME.HOME.NAME,
                        })
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "justify-space-between d-none d-md-flex",
                      attrs: { cols: "4" },
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "white--text text-decoration--none menu-item",
                          attrs: {
                            to: { name: _vm.ROUTES_HOME.HOW_IT_WORKS.NAME },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("header.what-is-mew")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "mx-8" },
                        [
                          _c("mew-menu", {
                            attrs: {
                              "top-arrow": "",
                              "activator-text-color": "white--text",
                              "list-obj": _vm.menuObj,
                            },
                            on: { goToPage: _vm.routeTo },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }