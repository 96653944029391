var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mew-component--landing-page-menu-mobile" },
    [
      _c("app-btn-menu", {
        nativeOn: {
          click: function ($event) {
            _vm.isOpen = !_vm.isOpen
          },
        },
      }),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            width: "280px",
            absolute: "",
            temporary: "",
            color: "white",
          },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "div",
            { staticClass: "pa-5", staticStyle: { position: "relative" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "close-button",
                  attrs: { large: "", icon: "", light: "" },
                  on: {
                    click: function ($event) {
                      _vm.isOpen = false
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "textDark" } }, [
                    _vm._v("mdi-window-close"),
                  ]),
                ],
                1
              ),
              _c("img", {
                attrs: {
                  src: require("@/assets/images/icons/icon-mew-dark.svg"),
                  height: "36",
                },
                on: {
                  click: function ($event) {
                    return _vm.pushRoute({ name: "Home" })
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-list",
            { staticClass: "px-2", attrs: { color: "white" } },
            [
              _vm._l(_vm.menu, function (item, index) {
                return [
                  !item.sub
                    ? _c(
                        "v-list-item",
                        { key: index },
                        [
                          item.to
                            ? _c(
                                "v-list-item-content",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.pushRoute(item.to)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mew-heading-3 font-weight-medium textDark--text",
                                    },
                                    [_vm._v(" " + _vm._s(item.label) + " ")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          item.url
                            ? _c(
                                "a",
                                {
                                  attrs: { href: item.url, target: "_blanks" },
                                  on: {
                                    click: function ($event) {
                                      _vm.isOpen = false
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "textDark--text" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mew-heading-3 font-weight-medium textDark--text",
                                        },
                                        [_vm._v(" " + _vm._s(item.label) + " ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  item.sub
                    ? _c(
                        "v-list-group",
                        {
                          key: index,
                          attrs: { "prepend-icon": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c("v-list-item-content", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mew-heading-3 font-weight-medium textDark--text",
                                        },
                                        [_vm._v(" " + _vm._s(item.label) + " ")]
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        _vm._l(item.sub, function (child, ckey) {
                          return _c(
                            "v-list-item",
                            {
                              key: ckey,
                              staticClass: "pl-4",
                              attrs: { dense: "" },
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  child.to
                                    ? _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "pl-4 textDark--text font-weight-regular mew-body",
                                          on: {
                                            click: function ($event) {
                                              return _vm.pushRoute(child.to)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(child.label) + " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  child.url
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: child.url,
                                            target: "_blanks",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.isOpen = false
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "pl-13 textDark--text font-weight-regular mew-body",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(child.label) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ]
              }),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { on: { click: _vm.openBuySell } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mew-heading-3 font-weight-medium textDark--text",
                        },
                        [_vm._v(" Buy ETH ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mew-heading-3 font-weight-medium textDark--text mb-6",
                        },
                        [_vm._v(" Products ")]
                      ),
                      _vm._l(_vm.mewProducts, function (product, keyProduct) {
                        return _c(
                          "a",
                          {
                            key: keyProduct,
                            staticClass: "d-flex align-center mb-5 ml-5",
                            attrs: { href: product.link, target: "_blank" },
                          },
                          [
                            _c("img", {
                              staticClass: "mr-3",
                              attrs: {
                                width: "35",
                                src: product.img,
                                alt: product.label,
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mew-heading-3 font-weight-medium textDark--text",
                              },
                              [_vm._v(" " + _vm._s(product.label) + " ")]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "pa-4 bottom-buttons" },
            [
              _c("mew-button", {
                staticClass: "mb-2",
                attrs: {
                  "btn-size": "large",
                  "has-full-width": "",
                  title: "Create a new wallet",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.$router.push({
                      name: _vm.ROUTES_HOME.CREATE_WALLET.NAME,
                      params: {},
                    })
                  },
                },
              }),
              _c("mew-button", {
                attrs: {
                  "btn-style": "outline",
                  "btn-size": "large",
                  "has-full-width": "",
                  title: "Access my wallet",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.$router.push({
                      name: _vm.ROUTES_HOME.ACCESS_WALLET.NAME,
                      params: {},
                    })
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }